.device_panel_container {
  padding: 2rem;
}
.spec_style {
  object-fit: fill;
  height: 13rem;
  width: 13rem;
}

.spec_box {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.red-icon {
  color: red;
}

.gallery-info-container {
  position: relative;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  float: center;
  /* left: 50%; */
  /* transform: translate(-50%, 0); */
}

.top-left {
  position: absolute;
  left: 10%;
  top: 10%;
}

.select_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dateTimeText {
  font-size: 1rem;
  font-weight: 500;
  color: #3f3f3f;
}

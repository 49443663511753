.device_panel_container {
  padding: 2rem;
  margin: 2rem;
}

.select_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

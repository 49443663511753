.timerRow {
  width: 100%;
  margin-bottom: 2rem;
  padding-bottom: 2rem;
}
.sectionTitle {
  font-size: 3.8rem;
}
.timerTitle {
  font-size: 1.8rem;
  color: #1677ff;
}
.disabledTimerTitle {
  font-size: 1.8rem;
  color: #c6c6c6;
}
.dateTimeText {
  font-size: 1rem;
  font-weight: 500;
  color: #3f3f3f;
}

.timerContainer {
  width: 75%;
}

.schedule-item {
  border: 1px solid #d9d9d9;
  height: 30px;
  width: 30px;
  position: relative;
  overflow: hidden;
}

.schedule-item-filler-1 {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.schedule-item-filler-2 {
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}

.schedule-item-filler-now {
  height: 110%;
  width: 2px;
  position: absolute;
  top: 0;
  background-color: red;
}

.schedule-header {
  height: 30px;
  width: 30px;
  transform: rotate(-45deg) translateX(5px) translateY(10px);
  text-wrap: nowrap;
  text-align: right;
  padding-bottom: 0.5rem;
  white-space: nowrap;
}

.schedule-row-header {
  text-align: center;
  transform: translateY(5px);
}

/* NAVIGATION BAR */

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  padding: 0 4vw;
  box-shadow: 0 2px 10px lightgray;
  background-color: #f4fbe9;
  z-index: 1;
}
.sticky {
  position: sticky;
  top: 0;
  left: 0;
}

nav span {
  color: #4caf50;
}

nav p {
  font-size: 25px;
  font-weight: 600;
  cursor: default;
}

nav li {
  display: flex;
  align-items: center;
  list-style: none;
}

nav a {
  text-decoration: none;
  color: #676767;
  padding: 0 1rem;
  transition: 0.1s ease-in-out;
  font-size: 22px;
}
nav a:hover {
  color: #121212;
}
.logo-header {
  cursor: pointer;
}

/***** GLOBAL CSS SECTION START ******/

.component_box_container {
  padding: 2rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-top: 2rem;
  min-height: 17rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

/***** GLOBAL CSS SECTION END ******/

.large-spinner {
  margin-right: 1rem;
  width: 20rem;
  height: auto;
}

.device-inner-box {
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.device-setup-title {
  color: rgb(75 85 99);
  font-weight: 500;
  padding-left: 0.5rem; /* 8px */
  padding-right: 0.5rem; /* 8px */
  margin-right: 52px;
}

.device-setup-scan-button {
  width: 6rem;
  height: 6rem;
  justify-content: center;
  align-items: center;
}

.bluetooth-icon {
  width: 5rem;
  height: 5rem;
}

.device-setup-icon-button {
  width: 1rem;
  height: 1rem;
}

.device-setup-close-button {
  position: absolute;
  left: 1rem;
  top: 3rem;
}

.device-setup-form {
  width: 100%;
}

.device-setup-connected-tag {
  position: absolute;
  top: 3rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

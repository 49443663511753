.alert-header-container {
  margin-bottom: 1rem;
}

.alert-dialog-container {
  margin-bottom: 1rem;
  cursor: pointer;
}

.alert-dialog-date-tag {
  float: right;
  margin-left: 12px;
}

.alert-dialog-message {
  float: left;
}

.alert-dialog-tag-container {
  float: left;
}
